import { colors } from '@styles/vars/colors.style'
import { mq } from '@styles/vars/media-queries.style'
import styled from 'styled-components'

export const ServiceMain = styled.div`
  position: relative;
  background-color: ${props => (props.reverse ? colors.cream : colors.light)};
`

export const ServiceImage = styled.div`
  overflow: hidden;
  border-radius: 0.8rem;

  ${mq.tabletPMax} {
    margin-bottom: 3.2rem;
  }
`
